<template >
  <div id="app"  >


    <router-view />







  </div>
</template>

<script>
// 组件
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'app',
  components: {
    HelloWorld,
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-left: 1.5%;
  padding-top: 1.5%;
  padding-right: 1.5%;
}



</style>
