import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        /** 模板之标签管理 **/
        path: '/labelManagement',
        name: 'labelManagement',
        component: () => import('../views/robot/template/labelManagement.vue')
    },
    {
        /** 模板之标签管理 **/
        path: '/warn',
        name: 'warn',
        component: () => import('../views/robot/warn/warn.vue')
    },
    {
        /** 话术流程 **/
        path: '/easyFlow',
        name: 'easyFlow',
        component: () => import('../views/robot/ef/panel.vue')
    },
    {
        /** 变量配置 **/
        path: '/ivrVariable',
        name: 'ivrVariable',
        component: () => import('../views/robot/ivrVariable/index.vue')
    },
    {
        /** 操作日志 **/
        path: '/actionLog',
        name: 'actionLog',
        component: () => import('../views/robot/log/actionLog.vue')
    },
    {
        /** 黑名单 **/
        path: '/blackList',
        name: 'blackList',
        component: () => import('../views/robot/blackList/blackList.vue')
    },
    {
        /** 黑名单分组 **/
        path: '/blackListGroup',
        name: 'blackListGroup',
        component: () => import('../views/robot/blackList/blackListGroup.vue')
    },
    {
        /** 回调配置 **/
        path: '/callback',
        name: 'callback',
        component: () => import('../views/robot/callback/callback.vue')
    },
    {
        /** 线路组配置 **/
        path: '/routeGroup',
        name: 'routeGroup',
        component: () => import('../views/robot/route/routeGroup.vue')
    },
    {
        /** 主叫号码配置 **/
        path: '/caller',
        name: 'caller',
        component: () => import('../views/robot/caller/caller.vue')
    },
    {
        /** 基础配置 **/
        path: '/settings',
        name: 'settings',
        component: () => import('../views/robot/basicsSettings/settings.vue')
    },
    {
        /** 外呼服务器配置 **/
        path: '/callerServer',
        name: 'callerServer',
        component: () => import('../views/robot/callerServer/callerServer.vue')
    },
    {
        /** 机器人配置 **/
        path: '/botSettings',
        name: 'botSettings',
        component: () => import('../views/robot/robot/settings.vue')
    },
    {
        /** 用户 **/
        path: '/user',
        name: 'user',
        component: () => import('../views/robot/user/user.vue')
    },
    {
        /** 用户 **/
        path: '/userCaller',
        name: 'userCaller',
        component: () => import('../views/robot/user/userCaller.vue')
    },
    {
        /** 模板配置 **/
        path: '/template',
        name: 'template',
        component: () => import('../views/robot/template/xls.vue')
    },
    {
        /** 外呼任务统计报表 **/
        path: '/outboundCallTaskReport',
        name: 'outboundCallTaskReport',
        //component: () => import('../views/robot/report/outboundCallTaskReport.vue')
        component: () => import('../views/robot/report/task.vue')
    },
    {
        /** 任务统计报表 **/
        path: '/taskReport',
        name: 'taskReport',
        component: () => import('../views/robot/report/taskReport.vue')
    },
    {
        /** 机器人外呼统计报表 **/
        path: '/robotOutboundCallReport',
        name: 'robotOutboundCallReport',
        component: () => import('../views/robot/report/robotOutboundCallReport.vue')
    },
    {
        /** 录音管理 **/
        path: '/trecords',
        name: 'trecords',
        component: () => import('../views/robot/records/index.vue')
    },
    {
        /** 任务框架 **/
        path: '/task',
        name: 'task',
        component: () => import('../views/robot/task/index.vue')
    },
    {
        /** 饼图1 **/
        path: '/echarts1',
        name: 'echarts1',
        component: () => import('../views/robot/task/echarts1.vue')
    },
    {
        /** 饼图2 **/
        path: '/echarts2',
        name: 'echarts2',
        component: () => import('../views/robot/task/echarts2.vue')
    },
    {
        /** 饼图3 **/
        path: '/echartOfDiallogRound',
        name: 'echartOfDiallogRound',
        component: () => import('../views/robot/task/echartOfDiallogRound.vue')
    },
    {
        /** 柱状图4 **/
        path: '/echartOfIntent',
        name: 'echartOfIntent',
        component: () => import('../views/robot/task/echartOfIntent.vue')
    },
    {
        /** 客户名单 **/
        path: '/customer',
        name: 'customer',
        component: () => import('../views/robot/customer/customer.vue')
    },
    {
        /** 客户类型 **/
        path: '/custType',
        name: 'custType',
        component: () => import('../views/robot/customer/customerType.vue')
    },
    {
        /** 外呼明细 **/
        path: '/outBoundDetails',
        name: 'outBoundDetails',
        component: () => import('../views/robot/task/outBoundDetailsOfRepeat.vue')
    },
    {
        /** 机器人管理 **/
        path: '/bot',
        name: 'bot',
        component: () => import('../views/robot/robot/index.vue')
    },
    {
        /** 系统管理-下载中心 **/
        path: '/system-download',
        name: 'systemDownload',
        component: () => import('../views/robot/system/download.vue')
    },
    {
        /** 系统管理-录音变量 **/
        path: '/variables-files',
        name: 'variableWavFiles',
        component: () => import('../views/robot/system/variableWavFiles.vue')
    },
    {
        /** 系统管理-录音管理 **/
        path: '/recordIndex',
        name: 'recordIndex',
        component: () => import('../views/robot/system/recordIndex.vue')
    },
    {
        /** demo **/
        path: '/demo',
        name: 'demo',
        component: () => import('../views/robot/task/demo.vue')
    },
    {
        /** test1 **/
        path: '/test1',
        name: 'test1',
        component: () => import('../views/robot/test/test1.vue')
    },
    {
        /** test2 **/
        path: '/test2',
        name: 'test2',
        component: () => import('../views/robot/test/test2.vue')
    },
    {
        /** test3 **/
        path: '/test3',
        name: 'test3',
        component: () => import('../views/robot/test/test3.vue')
    },
    {
        /** test1 **/
        path: '/test',
        name: 'test',
        component: () => import('../views/robot/test/test.vue')
    },

    {
        /** test0 **/
        path: '/test0',
        name: 'test0',
        component: () => import('../views/robot/test/test0.vue')
    },
    {
        /** 短信 **/
        path: '/sms',
        name: 'sms',
        component: () => import('../views/robot/sms/sms.vue')
    },
    {
        /** 外呼拦截 **/
        path: '/interceptLog',
        name: 'interceptLog',
        component: () => import('../views/robot/task/interceptLog.vue')
    },
    {
        /** 短信 **/
        path: '/smsDial',
        name: 'smsDial',
        component: () => import('../views/robot/sms/smsDial.vue')
    },
    {
        /** 预警明细 **/
        path: '/warnDial',
        name: 'warnDial',
        component: () => import('../views/robot/warn/warnDial.vue')
    },
    {
        /** 催收报表 **/
        path: '/repay',
        name: 'repay',
        component: () => import('../views/robot/report/repay.vue')
    },
    {
        /** 电销报表 **/
        path: '/intention',
        name: 'intention',
        component: () => import('../views/robot/report/intention.vue')
    },

]

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router

// router.beforeEach((to, from, next) => {
//   //如果去这个页面 执行
//   if(to.name === 'login' || to.name === 'userLogin'){
//     next(); //放行，不拦截
//   }else{
//     // let user = JSON.parse(sessionStorage.getItem("user"));
//     let token = sessionStorage.getItem("token");
//     let isLogin = token?true:false;
//
//     if (!isLogin) {
//       next({ name: 'login' })  //没有userId，定向到登录页面
//     }else{
//       next(); // 有userId，说明已经登录，放行
//     }
//   }
// })
